import React, {Component} from 'react';
import BlogUI from "../Presentation/BlogUI";


class Blog extends Component{

    render(){
        return(
            <BlogUI/>
        );
    }
}


export default Blog;