import React, {Component} from 'react';
import BlockchainUI from "../Presentation/BlockchainUI";


class Blockchain extends Component{

    render(){
        return(
            <BlockchainUI/>
        );
    }
}


export default Blockchain;