import React, {Component} from 'react';
import NavigationUI from '../Presentation/NavigationUI';
import '../../css/Navigation.css'

class Navigation extends Component {

   

    
    render(){

        
        return(

           
            <NavigationUI/>
        );
    }
}
export default Navigation;