import React, {Component} from 'react';
import DigitalmarketingUI from "../Presentation/DigitalmarketingUI";


class Digitalmarketing extends Component{

    render(){
        return(
            <DigitalmarketingUI/>
        );
    }
}


export default Digitalmarketing;