import React, {Component} from 'react';
import Qatesting_blogUI from "../../Presentation/Blogs/Qatesting_blogUI";


class Qatesting_blog extends Component{

    render(){
        return(
            <Qatesting_blogUI/>
        );
    }
}


export default Qatesting_blog;