import React from 'react';
import '../../css/Footer.css'


const FooterUI = (props)=>(

    <div>

        <div className="footer">


            <div className="footercontacts">

                    <div className="footercontactsitem">
                        <a href="https://www.facebook.com/mudrainfotec/ "><i class="fab fa-facebook" ></i></a>
                    </div>
                    <div className="footercontactsitem"> 
                        <a href="https://www.linkedin.com/company/mudrainfotec"><i class="fab fa-linkedin"></i></a>
                    </div>
                    <div className="footercontactsitem"> 
                        <a href="https://www.google.com"><i class="fa fa-envelope"></i></a>
                    </div> 

            
            </div>

            
            <div className="footer-navdown">

                <div className="footernav-item">

                    <a href="#">Home</a>



                </div>

                <div className="footernav-item">

                    <a href="/Aboutus">About Us</a>



                </div>

                <div className="footernav-item">

                    <a href="/#service">Services</a>



                </div>

                <div className="footernav-item">

                    <a href="/contactus">Contact Us</a>



                </div>

                <div className="footernav-item">

                    <a href="/career">Career</a>



                </div>





            </div>


            <div className="Copyright">

                <p> Mudrainfotech </p>
                <p> Copyright © 2019 All right reserved </p>


            </div>







        </div>

        










    </div>


)

export default FooterUI;