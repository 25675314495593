import React from 'react';
import '../../css/Digitalmarketing.css'
import D from '../../Static/Images/digital mar.png';
import Seo from '../../Static/Images/seo.jpg';
import inbound from '../../Static/Images/inboundmarketing.jpg';
import content from '../../Static/Images/images (6).jpg';
import digital from '../../Static/Images/digital-marketing.png';

const DigitalmarketingUI = (props)=>(


    <div>
            <div className="banner-img">
        
                <div className="Digitalmarket">

                    <div className="DigiatlleftContent">
                        <h1>Digital <br></br> Marketing</h1>
                        <p>Strategies Focused on increasing <br></br> The Reach & visibility of your
                         Business</p>
        
                        <div className="learn">
                            <a class="btn btn-primary" href="/Digitalmarketing#Service_dm" role="button"> Learn More </a>
                        </div>
                    </div>

                    <div className="Digiatlimage">
                    <img src = {D} alt = ''/>

                    </div>
       
       
                 </div>
            </div>


            <div id="Service_dm">
                <div className="services">
                <div className="ourProduct"><h1>Services</h1></div>

                    <div className="allProducts">
                        <div className='firstlogo'>
                            <div class="logoHeading">
                            <img src = {Seo} alt = ''/>
                            </div>
                    
                            <div className="heading">
                                Search engine Optimization
                            </div>
                            <div className="logoContent">
                            SEO processes are designed taking into consideration the Google’s SEO
                             guidelines, and organic SEO techniques which are strictly followed to guarantee 
                            that our clients get the best results through our SEO services.
                            </div>
                        </div>
                        <div class='firstlogo'>
                            <div class="logoHeading">
                                <img src = {inbound} alt = ''/>
                            </div>
                            <div class="heading">
                                Inbound marketing
                            </div>
                            <div class="logoContent">
                            Inbound marketing is about creating valuable experiences that 
                            have a positive impact on people and your business. How do you do that? You attract prospects and customers
                             to your website and blog through relevant and helpful content
                            </div>
                        </div>

                        <div class='firstlogo'>
                            <div class="logoHeading">
                                <img src = {content} alt = ''/>
                            </div>
                            <div class="heading">
                                Content Marketing
                            </div>
                            <div class="logoContent">
                            Content marketing is a strategic marketing approach focused on creating a
                            nd distributing valuable, relevant, and consistent content to attract
                             and retain a clearly defined audience — 
                            and, ultimately, to drive profitable customer action.
                            </div>
                        </div>
            <div class='firstlogo'>
                    <div class="logoHeading">
                    <img src = {digital} alt = ''/>
                    </div>
                    <div class="heading">
                        Digital Marketing Strategy
                    </div>
                    <div class="logoContent">
                            An effective digital strategy will help you take the
                             right decisions to make a company successful online. 
                              A strategy process model provides a framework that gives 
                              a logical sequence to follow to ensure inclusion of all key activities of strategy development and implementation. We recommend the SOSTAC planning approach.


                    </div>
            </div>
            
            </div>
            </div>
        </div>
    




    </div>
    
        
    


)

export default DigitalmarketingUI;