import React, {Component} from 'react';
import UsitUI from "../Presentation/UsitUI";


class Usit extends Component{

    render(){
        return(
            <UsitUI/>
        );
    }
}


export default Usit;