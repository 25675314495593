import React from 'react';
import '../../css/Contactus.css'
import pic from '../../Static/Images/bb.jpg'



const ContactusUI = (props)=>(

    <div>

        <div className="contactus-banner">

            <div className="contactus-header">
                <h1> Contact Us </h1>
                <p>Feel free to contact us</p>

            </div>

        </div>

        <div className="address">

            <div className="address-list">

                <div className="address-item">

                    <div className="address-itemheader">

                            <div className="Address-icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>

                        <h2>Canada Office</h2>

                        

                        <p>38 Dufay Road<br></br>
                                Brampton <br></br>
                                L7A4A2, canada .<br></br>
                                Mobile: +1(647)302-0059<br></br>
                                (315)715-8369</p>


                    </div>


                </div>

                <div className="contactus-pic">

                    <img src = {pic} alt = ''/>

                </div>

                <div className="address-item">
                    <div className="address-itemheader">

                            <div className="Address-icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>

                        <h2>India Office</h2>
                       <p> 808, Best Sky Tower,<br></br>
                                Netaji Subhash Place<br></br>
                                Pitampura, New Delhi 110034.<br></br>
                                Mobile: +91 8130388050<br></br>
                                         01149056951</p>

                    </div>



                </div>









            </div>










        </div>

        <div className="form-banner">
        <div className="form">

            <div className="container">
                
            <div className="jumbotron">
                <h1> Submit your Query </h1>
                <h2> please note : All fields are required </h2><br></br>

                <div class="form-group">
                    <label for="frst"> First name :</label>
                    <input class="form-control"id ="frst " type="text" name="firstname" placeholder="first name"required></input><br></br>
                    <label for="lst"> last name :</label>
                    <input class="form-control"id="lst" type= "text" name="lastname" placeholder="last name"required></input><br></br>
                    <label for="lst"> Contact No :</label>
                    <input class="form-control"id="lst" type= "text" name="lastname" placeholder="eg.XXXXXXXXXX"required></input><br></br>
                    <label for="lst"> Description :</label>
                    <input class="form-control"id="lst" type= "text-area" name="lastname" placeholder="Your Query"required></input><br></br>
                    

  
                    <button class="btn btn-success btn-lg" type="submit" name="submit"> submit</button>
      
        
                </div>
        
            </div>
            
            </div>
    
        </div>
        </div>

               
    	










            





        












    </div>



)

export default ContactusUI;