import React from 'react';
import '../../css/Blog.css'
import bc from './../../Static/Images/bc.jpg';
import testing from './../../Static/Images/AUTOMATION.jpg';
import robotic from './../../Static/Images/robotic.png';
import { Link } from 'react-router-dom';


const BlogUI = (props)=>(


    <div>

        <div className="blog-header">

            <h1>BLOGS</h1>

        </div>



        <div className="blog-layout">


            <div className="blog">

                <div className="blog-list">

                    <div className="blog-item">

                        <div className="blog-img">

                            <img src = {bc} alt = ''/>

                        </div>

                        <div className="blog-heading">

                            <h1>Blockchain Usage in Insurance and Healthcare Sector</h1>

                        </div>

                        <div className="blog-content">

                            <p>Blockchain is distributed and immutable (write once and read only) record of digital events that is
                                shared peer to peer between different parties (networked database systems). Blockchain transactions
                                are logged publicly and in chronological order.
                            </p>

                        </div>

                        <div className="blog-button">

                            {/* <Link class="btn btn-danger" to="/blog/blockchain#1"> Read More</Link> */}

                            <a href="/blog/blockchain#1" class="btn btn-info" role="button">Read More</a>
                        </div>

                    </div>    


                   {/* 2 blog  */}



                    <div className="blog-item">

                        <div className="blog-img">

                            <img src = {testing} alt = ''/>

                        </div>

                        <div className="blog-heading">

                            <h1>Automation Testing in Agile Environment</h1>

                        </div>

                        <div className="blog-content">

                            <p>We need to ensure that a considerable amount of time is required for these tasks and in an agile environment where an average sprint takes an average 1-2 weeks to complete and thus it is obviously too difficult to contemplate affording so much time for automating scripts in such a way
                            </p>

                        </div>

                        <div className="blog-button">

                            {/* <Link class="btn btn-danger" to="/blog/blockchain#1"> Read More</Link> */}

                            <a href="/blog/qatesting#1" class="btn btn-info" role="button">Read More</a>
                        </div>

                    </div> 


                        {/* 3 blog  */}

                    {/* <div className="blog-item">

                        <div className="blog-img">

                            <img src = {robotic} alt = ''/>

                        </div>

                        <div className="blog-heading">

                            <h1>The Future: Robotic Process Automation</h1>

                        </div>

                        <div className="blog-content">

                            <p>The term “Robot” is metaphorical, referring to a software product which provides a generic automation capability and configured within the end-user environment to execute manual and repetitive tasks. RPA are easy to configure, require little IT experience and can be quickly ‘trained’ and deployed to automate manual task. 
                            </p>

                        </div>

                        <div className="blog-button">

                            <Link class="btn btn-danger" to="/blog/blockchain#1"> Read More</Link>

                            <a href="/blog/robotics#1" class="btn btn-info" role="button">Read More</a>
                        </div>

                    </div>  */}


                   
                  


                  
                   


                    

                </div>

            </div>


            <div className="blog-category">

                <div className="blogcategory-header">

                    <h2>Categories</h2>

                </div>

                <div className="blogcategory-list">
              
                    <ol>
                    <li><a href="#">Blockchain</a></li>
                    <li><a href="#">Digital Marketing</a></li>
                    <li><a href="#">QA-Testing</a></li>
                    <li><a href="#">Website Development</a></li>
                    <li><a href="#">Mobile App Development</a></li>
                    <li><a href="#">IOT</a></li>
                    <li><a href="#">Robotic Process Automation</a></li>
                    </ol>
                </div>


            </div>

        </div>








    </div>



);

export default BlogUI;