import React from 'react';
import '../../css/Blockchain.css';
import B from '../../Static/Images/block-defi.png';


const BlockchainUI = (props)=>(


    <div>

        <div className="blockchain-banner">

        </div>

        <div className="blockchain-definition">
            <div className="blockchain-definitionlist">
                    <div className="blockchain-img">

                    <img src = {B} alt = ''/>
                        
                    </div>

                    <div className="blockchaindefine-box">

                        <div className="blockchaindefine-content">
                            <p>The blockchain advancement organization that conveys you with business-profiting arrangements helping you go too far and enter the future. Discover master engineers helping you turn the uniqueness of blockchain to your points of interest. We give you Blockchain improvement benefits that enable you to amplify your comprehension of blockchain to something other than bitcoin wallet application advancement.</p>
                        </div>

                    </div>
            </div>
        
        </div>

        <div className="blockchain-explain">

            <div className="blockchainexplain-header">
                <h1>Brief about Blockchain</h1>
            </div>

            <div className="blockchainexplain-list">

                <div className="blockchainexplain-item">
                    
                    <p>Blockchain network is a distributed ledger that records all the transactions that take place on the network.A blockchain ledger is often described as decentralized because it is replicated across many network participants, each of whom collaborate in its maintenance. The information recorded to a blockchain is append-only, using cryptographic techniques that guarantee that once a transaction has been added to the ledger it cannot be modified</p>
                </div>

            </div>


        </div>

        <div className="blockchain-services">

            <div className="blockchainservices-header">
                <h1>Services</h1>

            </div>

            <div className="blockchainservices-list">

                <div className="blockchainservices-item">


                    <div className="blockchainservicesitem-header">
                        <h3><strong>Blockchain Technology Solutions</strong></h3>


                    </div>

                    <div className="blockchainservicesitem-content">
                        <p>From Cryptocurrencies, hash algorithms, architectures, individual nodes to distributed ledger technology, we at mudrainfotech cover it all in our Blockchain development. Proof of work, concept models and Stake are an integral part of our consensus protocol which help us in the eradication of double spending error. </p>


                    </div>

                </div>

                
                <div className="blockchainservices-item">
                    <div className="blockchainservicesitem-header">
                        <h3><strong>Blockchain Smart Contract Development</strong></h3>


                    </div>

                    <div className="blockchainservicesitem-content">
                        <p>We do blockchain smart contract development, supply decentralized web solutions for ecommerce, real estate, finance, supply chain, gaming industries and other services. We do smart contract audit tool development for data integrity and optimum performance </p>


                    </div>
                    




                </div>

                
                <div className="blockchainservices-item">

                    <div className="blockchainservicesitem-header">
                        <h3><strong>Blockchain Wallets and Exchange Applications</strong></h3>


                    </div>

                    <div className="blockchainservicesitem-content">
                        <p>Our blockchain application services include Blockchain enabled blockchain app development for desktops, mobile and browser apps. We also do bitcoin wallet app development with inbuilt features such as trading multiple cryptocurrencies, blockchain tokens, online purchases, participating in coin offerings, auto-generating private and public keys. </p>


                    </div>




                </div>


            </div>






        </div>













    </div>

)
export default BlockchainUI;