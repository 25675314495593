import React, {Component} from 'react';
import ContactusUI from "../Presentation/ContactusUI";


class Contactus extends Component{

    render(){
        return(
            <ContactusUI/>
        );
    }
}


export default Contactus;
