import React, {Component} from 'react';
import WebappUI from "../Presentation/WebappUI";


class Webapp extends Component{

    render(){
        return(
            <WebappUI/>
        );
    }
}


export default Webapp;