import React from 'react';
import '../../css/Rpa.css';
import rpagif from './../../Static/Images/rpa.gif';






const RpaUI = (props)=>(

    <div>

        <div className="rpa-banner">

            <div className="rpa-header">

                <h1>Robotic Process <br></br>Automation</h1>
            </div>

            

        </div>


        <div className="rpa-define">

            <div className="rpadefine-list">


              <div className= "rpadefine-item">
                <div className="rpadefine-itembox">

                        <div className="rpadefine-content">
                            <p>Robotic Process Automation is a set of technologies that use software as a virtual employee to manipulate existing application software in the same way that a person completes the process. This helps eliminate or reduce human intervention in the execution of tasks and decision making.</p>
                        </div>

                </div>

              </div>
                

                <div className="rpadefine-gif">

                    <img src = {rpagif} alt = '' />


                </div>

             
            </div>
                
          


        </div>

        <div className="rpa-services">

            <div className="rpaservices-header">

                <h1>How Mudrainfotech implement Robotic Process Automation ?</h1>

            </div>

            <div className="rpaservices-list">

                <div className="rpaservices-item">

                    <div className="rpaservicesitem-header">
                        <h3>Automation capability Transfer </h3>

                    </div>

                    <div className="rpaservicesitem-content">
                        <p>Training and knowledge transfer to business process owners and relevant staff to achieve greater self-sufficiency in the maintenance of automation designs. </p>

                    </div>


                </div>

                <div className="rpaservices-item">

                    <div className="rpaservicesitem-header">
                        <h3>Agile Design and Delivery </h3>

                    </div>

                    <div className="rpaservicesitem-content">
                        <p>Rapid design, prototyping and implementation of process automation solutions.</p>

                    </div>


                </div>

                <div className="rpaservices-item">

                    <div className="rpaservicesitem-header">
                        <h3>Scanning and Analysis </h3>

                    </div>

                    <div className="rpaservicesitem-content">
                        <p>Scanning, Mapping and evaluating the existing process landscape and underpinning systems to identify.</p>

                    </div>


                </div>

                



            </div>
        </div>







    </div>
)

export default RpaUI;