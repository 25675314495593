import React, {Component} from 'react';
import QatestingUI from "../Presentation/QatestingUI";


class Qatesting extends Component{

    render(){
        return(
            <QatestingUI/>
        );
    }
}


export default Qatesting;