import React, {Component} from 'react';
import Robotics_blogUI from "../../Presentation/Blogs/Robotics_blogUI";


class Robotics_blog extends Component{

    render(){
        return(
            <Robotics_blogUI/>
        );
    }
}


export default Robotics_blog;