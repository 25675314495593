import React, {Component} from 'react';
import MobappUI from "../Presentation/MobappUI";


class Mobapp extends Component{

    render(){
        return(
            <MobappUI/>
        );
    }
}


export default Mobapp;