import React, {Component} from 'react';
import IotUI from "../Presentation/IotUI";


class Iot extends Component{

    render(){
        return(
            <IotUI/>
        );
    }
}


export default Iot;
