import React from 'react';
import '../../css/AI.css';
import AIgif from './../../Static/Images/AI.gif';





const AIUI = (props)=>(

    <div>

        <div className="ai-banner">

            <div className="ai-header">

                <h1>Artificial <br></br>Intelligence</h1>
            </div>

        </div>


        <div className="ai-define">

            <div className="aidefine-list">


                <div className="aidefine-gif">

                    <img src = {AIgif} alt = '' />


                </div>
                <div className="aidefine-item">

                    <div className="aidefine-content">
                        <p>AI is having a huge impact on businesses today. Companies who are making use of data and AI are reporting higher revenues, company growth, more profits, and market-leading performance.</p>
                    </div>

                </div>

                
            </div>


        </div>


        <div className="ai-services">

            <div className="aiservices-header">

                <h1>AI Services</h1>

            </div>

            <div className="aiservices-list">

                <div className="aiservices-item">

                    <div className="aiservicesitem-header">
                        <h3>Lead Generation </h3>

                    </div>

                    <div className="aiservicesitem-content">
                        <p>Machine Learning algorithms establish trends, capture patterns and build models for predictions from internal database, to then find new leads from unstructured data in the web. Marketing and sales teams can spend more time speaking to hot leads and closing sales instead of putting efforts in finding new leads. </p>

                    </div>


                </div>

                <div className="aiservices-item">

                    <div className="aiservicesitem-header">
                        <h3>Analysis of Trends </h3>

                    </div>

                    <div className="aiservicesitem-content">
                        <p>Intelligent gathering and processing of web, social media, and public data using Machine Learning and Deep Learning methods, AI-powered trend analysis can provide you with a unique dataset and insights that let you make important business decisions. Company will be able to access accurate data as it becomes available, and make strategic choices based on real time information</p>

                    </div>


                </div>

                <div className="aiservices-item">

                    <div className="aiservicesitem-header">
                        <h3>Price Monitoring  </h3>

                    </div>

                    <div className="aiservicesitem-content">
                        <p>When it comes to dynamic price optimization at scale, Machine Learning based web extraction tools can save you time and effort. With highly accurate results, you can track thousands of competitor sites and act accordingly. Besides the competitor’s price analysis, AI price monitoring engines can be applied to track customer products; to ensure contractual compliance; to forecast purchasing trends for procurement; to monitor prices of stocks and other assets involved in buying decisions.</p>

                    </div>


                </div>

                <div className="aiservices-item">

                    <div className="aiservicesitem-header">
                        <h3>Alternative Data </h3>

                    </div>

                    <div className="aiservicesitem-content">
                        <p>Machine Learning and Natural Language Processing are used to extract valuable data points from one of the largest data sets in the world; the web. By training an AI system to recognize contextually important information within an unstructured data set, you can gain vast and competitive alternative data sets to power your business decisions. </p>

                    </div>


                </div>



            </div>
        </div>

























    </div>



























)
export default AIUI;