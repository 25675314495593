import React from 'react';
import '../../css/Mobapp.css';
import mobgif from '../../Static/Images/mobapp.gif';







const MobappUI = (props)=>(

    <div>

        <div className="mobapp-banner">

            <div className="mobapp-header">

                <h1>Mobile Application <br></br>Development</h1>
            </div>



        </div>


        <div className="mobapp-define">

            <div className="mobappdefine-list">


                <div className= "mobappdefine-item">
                    <div className="mobappdefine-itembox">

                        <div className="mobappdefine-content">
                            <p>Mudrainfotech is your best choice for Mobile App Development Company in Delhi. We create the Mobile app for Android and iOS that clients love to get their fingers moved on it again and again and grow your business.</p>
                        </div>

                    </div>

                </div>
    

                <div className="mobappdefine-gif">

                    <img src = {mobgif} alt = '' />


                </div>

 
            </div>
    



        </div>

        <div className="mobapp-services">

            <div className="mobappservices-header">

                    <h1>Android Application Development</h1>

            </div>

            <div className="mobappservices-list">

                <div className="mobappservices-item">

                    {/* <div className="mobappservicesitem-header">
                        <h3>Automation capability Transfer </h3>

                    </div> */}

                    <div className="mobappservicesitem-content">
                        <p>The google OS which powers 80 % of all smartphone in the global market, is undoubtly dominating the ‘World of Operating Systems’. Android application is taking an edge over other mobile operating systems around the globe. The open eco- system of Android has worked in favor of google, running most of the major smartphones in the World today and has become most favored platform. Android application developed gives your customers and employees easy access to your business and in return gives you huge profit. </p>

                    </div>


                </div>

                

    



            </div>
        </div>





























    </div>


)

export default MobappUI;