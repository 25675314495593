import React from 'react';
import '../../css/Usit.css'
import Comp from '../../Static/Images/computer.jpg';

const UsitUI = (props)=>(

    <div>



        <div className="Usitbanner-img">
           
           <h1>We are Hiring !</h1>
           <p>US IT Recuitment</p>
        </div>


        
        <div className="IT">
            <h1>IT</h1>
            <p>Welcome to future of technology</p>
        </div>
        <div className="IT_content">
            <div className="IT_heading">
            <h1>FROM DATA TO<br></br>
                DECISIONS</h1></div>

            <div className="IT_heading_content"><p>
                We understand and recognize that while technologies evolve rapidly, a skilled and flexible workforce remains at its unwavering core to deliver value out of mainstream technologies. While Digital Transformation is the foundation of the services we provide, we deliver it through Talent Supplementation using a combination of internal resources and a validated global talent network uniquely identified and engaged across projects and placements.

                We are proud of our engagements with leading corporations across the U.S. and India, helping them staff their IT needs on time, every time. Our exposure to technology and our journey with leading progressive companies has today evolved in to a practice-based approach that also serves as a platform to the Digital Transformation capabilities we offer.
            </p>
            </div>
        </div>
        <div className="IT_process_heading">

            <h1>How do the IT business processes work?</h1>
        </div>


        <div className="process_all_wrap">
   
            <div className="process_image_content">
                <p>IT business processes standardize all the activities of the company 
           related to the information technology, bringing them to an elevated stat
           e of quality and excellence.</p> With the IT business processes, the services can
            assure the delivery, no matter who executes them.<br></br>

           The absence of clearly defined IT business processes increases the chances for 
           errors in the processes of the company. As a result, in a future replacement of
            employees, all the structure would be compromised. In contrast, the presence of an
             IT governance with well-modeled processes allows that any employee of the area 
             attends to the projects in a satisfactory way, even in critical and emergency 
             situations. It works like a business continuity plan.<br></br>
           
           <p>IT business processes must be considered as an integral part of the business process 
           management.</p> Therefore, it has to receive many resources and investments, so they can be
            improved and optimized in a continuous way, contributing to the developing and growth of
             the business
             </div>

             <div class="process_image">
        
                <a href="/"><img src = {Comp} alt = ''/></a>
            </div>

        </div>


























    </div>




)

export default UsitUI;
