import React, {Component} from 'react';
import FooterUI from "../Presentation/FooterUI";


class Footer extends Component{

    render(){
        return(
            <FooterUI/>
        );
    }
}


export default Footer;
