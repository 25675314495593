import React, {Component} from 'react';
import AIUI from "../Presentation/AIUI";


class AI extends Component{

    render(){
        return(
            <AIUI/>
        );
    }
}


export default AI;