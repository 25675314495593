import React, {Component} from 'react';
import LandingpageUI from "../Presentation/LandingpageUI";

class Landingpage extends Component{


    render(){
        return(
            
            <LandingpageUI/>
        );
    }
}
export default Landingpage;