import React, {Component} from 'react';
import RpaUI from "../Presentation/RpaUI";


class Rpa extends Component{

    render(){
        return(
            <RpaUI/>
        );
    }
}


export default Rpa;