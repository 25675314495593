import React from 'react';
import '../../css/Aboutus.css'
import G from "../../Static/Images/gaurav.jpg";
import V from '../../Static/Images/sir3.png';
import A from '../../Static/Images/sir2.png';
import cert from '../../Static/Images/cert.PNG';

const AboutusUI = (props) => (


  <div>

    <div className="Aboutus-banner">

      <div className="Aboutus-header">

        <h1>Who Are We ? </h1>


      </div>

      <div className="Aboutus-contentbox">

        <p>We are people who would help bridge gap between job
        seekers and companies and IT services We understand how much
        pain is it to find a suitable job as well as a candidate.
        Our team comprises of people who have been at both the ends
        and they know how tricky this situation gets.
        We hold expertise in Big Data, QA, SAP
        and Web Development and can provide excellent
                    solutions in short span of time</p>


      </div>





    </div>

    <div className="Aboutus-info">

      <div className="Aboutus-infolist">

        <div className="establish">

          <div className="establish-header">

            <h1> Established in 2019 </h1>
          </div>

          <div className="establish-content">
            <p>Our company "MudraInfotech" was established in April 2019 is a subsidiary of MH Mortgage Services Private Limited. MH Mortgage Services Private Limited is a privately held company, incorporated on 29th February 2016, The company has registered a Trademark by the name “MUDRA HOME” and hence further be known by this brand. Here We deal in development and designing software’s. There are many firms which are involved in the same tasks, but our strategy in conquering this business is and will be totally different. What we provide to industry is what we have expected from our peers. We are bunch of motivated people who want to go that extra mile for providing excellent people to you. Another vertical of ours deals with providing IT solutions. We have experts in QA automation, Web Development, Artificial Intelligence, Blockchain, IOT, SAP and can help you achieve desirable results within expected delivery time.</p>

          </div>

        </div>

        <div className="establish">

          <div className="establish-header">

            <h1> Our Goals </h1>
          </div>

          <div className="establish-content">
            <p>Our goal is help the companies to provide the right candidate. We are fully equipped with abundant number of qualified candidates. These candidates have been shortlisted after going through a technical assessment.</p>

          </div>



        </div>


      </div>





    </div>


    <div className="Team">
      <div className="Team-header">
        <h1>Our Team </h1>


      </div>

      <div className="Team-list">
        <div className="Team-itembox">

          <div className="Team-img">

            <img src={G} alt='' />

          </div>

          <div className="Team-content">

            <h3>Gaurav Gaur(CEO)</h3>
            <p>In his role as a CEO of Mudrainfotech, Gaurav Gaur focuses on Mudra Infotec vision to enable organisation to realize the full potential of recruitment, QA and Automation, Big Data, Web Development services without having to sacrifice cost, availability, continuity and services level. An MBA from Devi Ahilya University and B.E from Nagpur University with over 15 years of work experience in Financial Services and FMCG Industries. With companies like CitiFinancial, Merrill Lynch, IIFL and Amway.</p>

          </div>



        </div>



        <div className="Team-itembox">

          <div className="Team-img">

            <img src={V} alt='' />

          </div>

          <div className="Team-content">

            <h3>Varun Lau(CTO)</h3>
            <p>In his role as CTO, Varun brings a unique mixture of business acumen and technical expertise from the information technology (IT) industry to lead Mudra Infotec. Through a smart entrepreneurial lens, varun is our visionary leader and is responsible for our corporate strategies, globalization, partnerships, marketing and our unique company culture.</p>

          </div>



        </div>



        <div className="Team-itembox">

          <div className="Team-img">

            <img src={A} alt='' />

          </div>

          <div className="Team-content">

            <h3>Atul Pareek(CFO)</h3>
            <p>In his role as CFO, is responsible for upholding strong financial management and accountability while providing timely, accurate, and reliable financial information and enhancing internal control at an MBA from Symboisis University Pune with over 15 years of work experience in Financial Services. With companies like ICICI Bank, India Bulls, Barclays and IIFL.</p>

          </div>



        </div>



      </div>



    </div>


    <div className="certificate">

      <div className="certificate-sub">
        <h1> Reward and Recognition </h1>

        <h2> " MH Infotech Services is recognized as a start up by the Department for Promotion of Industry and Internal Trade. "</h2>

        <img src={cert} alt='' />

      </div>

    </div>













  </div>
























)
export default AboutusUI;