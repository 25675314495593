import React from 'react';
import '../../css/Landingpage.css'
import P1 from '../../Static/Images/m1.png';
import P2 from '../../Static/Images/m2.png';
import gif from '../../Static/Images/Mudrainfotec.gif';
import Shoperkart from '../../Static/Images/shoperkart.jpeg';
import mycred from '../../Static/Images/mycred.jpeg';

const LandingpageUI = (props)=>(
<div>
    
    <div className="banner">

        <div className="header">
            <h1> MUDRAINFOTECH </h1>
            <p>IT service for everyone</p>


        </div>

    
    </div>
    <div id="service">
    <div className="Services">
        <div className="Services-header">

            <h1> Services </h1>
            
        </div>


        <div className="services-itemlist">

            <div className="item-box">
                
                <div className="item">
                    <div className="icons">
                        <a href="#"><i class="fas fa-robot"></i></a>
                    </div>

                    <div className="item-heading">
                        <h2> Artificial inteligence</h2>
                    </div>

                    <div className="item-content">

                        <p>    Artificial intelligence (AI) is the simulation of human intelligence
                                processes by machines, especially computer systems. The process
                                includes learning and reasoning. Application of AI include expert
                                systems, speech recognition and machine vision.</p>
                    </div>

                    <div className="explore">
                        <a class="btn btn-primary" href="/AI" role="button"> Explore </a>
                    </div>

                </div>


            </div>

            <div className="item-box">
                <div className="item">
                    <div className="icons">
                        <a href="#"><i class="fas fa-desktop"></i></a>
                    </div>

                    <div className="item-heading">
                        <h2> Website Development</h2>
                    </div>

                    <div className="item-content">

                        <p>    It is the combination of server side scripts and client side scripts In server side scripting to handle the storage information but in client side scripts to presents information to user. a web application or web app is a client–server computer program that the client runs in a web browser. </p>
                    </div>

                    <div className="explore">
                        <a class="btn btn-primary" href="/Webapplication" role="button"> Explore </a>
                    </div>

                </div>


            </div>

            <div className="item-box">
                <div className="item">
                    <div className="icons">
                        <a href="#"><i class="fas fa-mobile-alt"></i></a>
                    </div>

                    <div className="item-heading">
                        <h2> Mobile Application</h2>
                    </div>

                    <div className="item-content">

                        <p>    Portable App advancement is one of the significant angles for development of any association. At Mudrainfotech , we structure local/cross stage applications according to the necessities of the client. We give Custom App Development ,Android App Development.</p>
                    </div>

                    <div className="explore">
                        <a class="btn btn-primary" href="/Mobile-Application" role="button"> Explore </a>
                    </div>
                </div>


            </div>

            

            <div className="item-box">
                <div className="item">
                    <div className="icons">
                        <a href="#"><i class="fas fa-search"></i></a>
                    </div>

                    <div className="item-heading">
                        <h2> QA Testing </h2>
                    </div>

                    <div className="item-content">

                        <p>    Mudrainfotech
                             Testing Services provides a comprehensive portfolio of solutions that help to reduce the risk of application or system failure and ensure organization’s systems operate seamlessly and consistently to meet the expectations of both business users and customers.</p>
                    </div>

                    <div className="explore">
                        <a class="btn btn-primary" href="/Qa-testing" role="button"> Explore </a>
                    </div>

                </div>


            </div>

            
            <div className="item-box">
                <div className="item">
                    <div className="icons">
                        <a href="#"><i class="fab fa-bitcoin"></i></a>
                    </div>

                    <div className="item-heading">
                        <h2> BlockChain </h2>
                    </div>

                    <div className="item-content">

                        <p>    A blockchain is a time-stamped series of immutable record of data that is managed by cluster of computers not owned by any single entity. Since it is a shared and immutable ledger, the information in it is open for anyone and everyone to see.</p>
                    </div>

                    <div className="explore">
                        <a class="btn btn-primary" href="/Blockchain" role="button"> Explore </a>
                    </div>

                </div>


            </div>

            <div className="item-box">
                <div className="item">
                    <div className="icons">
                        <a href="#"><i class="fab fa-internet-explorer"></i></a>
                    </div>

                    <div className="item-heading">
                        <h2> Digital Marketing </h2>
                    </div>

                    <div className="item-content">

                        <p>    Digital marketing is the marketing of products or services using digital technologies, mainly on the Internet, but also including mobile phones, display advertising.</p>
                    </div>
                    <div className="explore">
                        <a class="btn btn-primary" href="/Digitalmarketing" role="button"> Explore </a>
                    </div>
                </div>


            </div>

            <div className="item-box">
                <div className="item">
                    <div className="icons">
                        <a href="#"><i class="fas fa-shopping-bag"></i></a>
                    </div>

                    <div className="item-heading">
                        <h2> US IT Requirement </h2>
                    </div>

                    <div className="item-content">

                        <p>    Mudrainfotech specializes in placing application development, information security,networking, database development, help desk and technical support professionals in project, contract-to-hire and full-time positions.</p>
                    </div>

                    <div className="explore">
                        <a class="btn btn-primary" href="/Usit" role="button"> Explore </a>
                    </div>

                </div>


            </div>

            <div className="item-box">
                <div className="item">
                    <div className="icons">
                        <a href="#"><i class="fas fa-robot"></i></a>
                    </div>

                    <div className="item-heading">
                        <h2> Robotic Process Automation </h2>
                    </div>

                    <div className="item-content">

                        <p>    Robotic is a set of technologies that use software to manipulate existing application software in the same way that a person completes the process. This helps eliminate or reduce human intervention in the execution of tasks and decision making.</p>
                    </div>

                    <div className="explore">
                        <a class="btn btn-primary" href="/RPA" role="button"> Explore </a>
                    </div>

                </div>


            </div>

            <div className="item-box">
                <div className="item">
                    <div className="icons">
                        <a href="#"><i class="fas fa-plane-departure"></i></a>
                    </div>

                    <div className="item-heading">
                        <h2> Internet Of Things</h2>
                    </div>

                    <div className="item-content">

                        <p>    By connecting the physical world to the digital world, the Internet of Things (IoT) opens up a new business models and services and human-machine interaction paradigms.</p>
                    </div>

                    <div className="explore">
                        <a class="btn btn-primary" href="/IOT" role="button"> Explore </a>
                    </div>

                </div>


            </div>



            
            




        </div>




    </div>
    </div>

    
 
 <div class="portfolio">

 <div class="portfolioHead">
     <h1>Our Products</h1>
 </div>
     

  <div className="product-list">
 

 
    <div class="hvrbox">
      <a href="/"><img src = {P1} alt = '' class="hvrbox-layer_bottom"/></a>
        <div class="hvrbox-layer_top hvrbox-layer_slideup">
          <div class="hvrbox-text">VISIT
 
            <a href="https://www.mudrahome.com/"><i class="fas fa-external-link-alt"></i></a>
            <p>MudraHome</p>


          </div> 
        </div>
    </div>

    {/* <div className="hello">
        <img src = {gif} alt = '' />
    </div> */}
 
 
    <div class="hvrbox">
      <a href="/"><img src = {P2} alt = '' class="hvrbox-layer_bottom"/></a>
        <div class="hvrbox-layer_top hvrbox-layer_slideup">
          <div class="hvrbox-text">VISIT
  
            <a href="https://espertomodel.com/"><i class="fas fa-external-link-alt"></i></a>
            <p>Esperto Model</p>


      
          </div>
        </div>
      </div>
    
      
    <div class="hvrbox">
      <a href="/"><img src = {Shoperkart} alt = '' class="hvrbox-layer_bottom"/></a>
        <div class="hvrbox-layer_top hvrbox-layer_slideup">
          <div class="hvrbox-text">VISIT
 
            <a href="https://shoperkart.in"><i class="fas fa-external-link-alt"></i></a>
            <p>Shoperkart</p>


          </div> 
        </div>
    </div>
      
    <div class="hvrbox">
      <a href="/"><img src = {mycred} alt = '' class="hvrbox-layer_bottom"/></a>
        <div class="hvrbox-layer_top hvrbox-layer_slideup">
          <div class="hvrbox-text">VISIT
 
            <a href="https://www.mycredential.in/"><i class="fas fa-external-link-alt"></i></a>
            <p>My Credential</p>


          </div> 
        </div>
    </div>
      




</div>
      
 
  </div>   
    
    
    
    
    
    

            <div className="Address">
                <div className="Address-header">
                    <h1> Get In Touch </h1>
                </div>

                <div className="Address-list">

                   
                        <div className="Address-item">
                            <div className="Address-icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>

                            

                            <div className="Address-content">
                                38 Dufay Road<br></br>
                                Brampton <br></br>
                                L7A4A2, canada .<br></br>
                                Mobile: +1(647)302-0059<br></br>
                                (315)715-8369


                            </div>
                        </div>
                    


                    
                    
                        <div className="Address-item">
                            <div className="Address-icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>

                            <div className="Address-content">
                            808, Best Sky Tower,<br></br>
                                Netaji Subhash Place<br></br>
                                Pitampura, New Delhi 110034.<br></br>
                                Mobile: +91 8130388050<br></br>
                                         01149056951



                            </div>
                        </div>
                    


                    

                </div>
            </div>
            
            

            

            

</div>
)

export default LandingpageUI;