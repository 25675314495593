import React from 'react';
import Logo from "../../Static/Images/logo.png";





const NavigationUI = (props)=>(

  

 <nav class="navbar navbar-default">
    <div className="container-fluid">
      <div className="navbar-header">
         <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button> 
        
          
        <a href="/"><img src = {Logo} alt = ''/></a>
         
        
        
        
      </div>
  
      {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
      
      <div className=" navbar-collapse collapse" id="bs-example-navbar-collapse-1" aria-expanded="false">
        <ul className="nav navbar-nav navbar-right">

         
          <li><a href="/" className="try"><i class="fa fa-home" id="navicons" aria-hidden="true"></i>Home</a></li>

          
          
          <li><a href="/Aboutus" className="try"><i class="fa fa-info-circle" id="navicons" aria-hidden="true"></i>About us</a></li>
          <li><a href="/#service" className="try"><i class="fa fa-briefcase" id="navicons" aria-hidden="true"></i>Services</a></li>
          <li><a href="/blog" className="try"><i class="fa fa-info-circle" id="navicons" aria-hidden="true"></i>Blogs</a></li>
          <li><a href="/contactus" className="try"><i class="fa fa-mobile" id="navicons" aria-hidden="true"></i>Contact us</a></li>
          <li><a href="/career" className="try"><i class="fa fa-male" id="navicons" aria-hidden="true"></i>Career</a></li>
         
          
        </ul>
        
      </div>
    </div>
  </nav>  


);
export default NavigationUI;
