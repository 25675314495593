import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './App.css';

import Navigation from './Components/Container/Navigation';
import Landingpage from './Components/Container/Landingpage';
import Aboutus from './Components/Container/Aboutus';
import Contactus from './Components/Container/Contactus';
import Career from './Components/Container/Career';
import Blockchain from './Components/Container/Blockchain';
import Webapp from './Components/Container/Webapp';
import Digitalmarketing from './Components/Container/Digitalmarketing';
import Usit from './Components/Container/Usit';
import Qatesting from './Components/Container/Qatesting';
import AI from './Components/Container/AI';
import RPA from './Components/Container/Rpa';
import Mobapp from './Components/Container/Mobapp';
import IOT from './Components/Container/Iot';
import Footer from './Components/Container/Footer';
import Scrollbutton from './Components/Container/Scrollbutton';

import Blog from './Components/Container/Blog';
import Blockchain_blog from './Components/Container/Blogs/Blockchain_blog';
import Qatesting_blog from './Components/Container/Blogs/Qatesting_blog';
import Robotics_blog from './Components/Container/Blogs/Robotics_blog';


class App extends Component {

  render() {
    return (
      <div className="App">



        <BrowserRouter>
          <Navigation />


          <Switch>


            <Route
              exact path='/'
              component={Landingpage}
            />

            <Route
              exact path='/Aboutus'
              component={Aboutus}
            />

            <Route
              exact path='/Contactus'
              component={Contactus}
            />

            <Route
              exact path='/Career'
              component={Career}
            />

            <Route
              exact path='/Digitalmarketing'
              component={Digitalmarketing}
            />

            <Route
              exact path='/Usit'
              component={Usit}
            />

            <Route
              exact path='/Blockchain'
              component={Blockchain}
            />

            <Route
              exact path='/Webapplication'
              component={Webapp}
            />

            <Route
              exact path='/Qa-testing'
              component={Qatesting}
            />

            <Route
              exact path='/AI'
              component={AI}
            />

            <Route
              exact path='/RPA'
              component={RPA}
            />

            <Route
              exact path='/Mobile-Application'
              component={Mobapp}
            />

            <Route
              exact path='/IOT'
              component={IOT}
            />

            <Route
              exact path='/blog'
              component={Blog}
            />


            <Route
              exact path='/blog/blockchain'
              component={Blockchain_blog}
            />

            <Route
              exact path='/blog/qatesting'
              component={Qatesting_blog}
            />


            <Route
              exact path='/blog/robotics'
              component={Robotics_blog}
            />








          </Switch>

          <Scrollbutton />

          <Footer />





        </BrowserRouter>





      </div>
    );
  }
}

export default App;
