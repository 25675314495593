import React from 'react';
import '../../css/Qatesting.css';
import testgif from '../../Static/Images/testing.gif';




const QatestingUI = (props)=>(


    <div>
            <div className="qatesting-banner">

                <div className="qatesting-header">

                    <h1>QA <br></br>Testing</h1>
                </div>

            </div>

            <div className="qatesting-define">

                <div className="qatestingdefine-list">



                    <div className="qatestingdefine-gif">

                        <img src = {testgif} alt = ''/>



                    </div>

                    <div className="qatestingdefine-item">

                        <div className="qatestingdefine-content">
                            <p>With a comprehensive range of testing services, mudrainfotech can offer focused QA for almost any business requirement. Based on our experience, we have developed a series of standard solutions that fulfil specific business needs and are applicable across multiple industries.</p>
                        </div>

                    </div>

                    

                </div>
            </div>

            <div className="testing-services">

                <div className="testingservices-header">

                    <h1>Services</h1>

                </div>

                <div className="testingservices-list">

                    <div className="testingservices-item">

                        <div className="testingservicesitem-header">
                            <h3>Website Testing </h3>

                        </div>

                        <div className="testingservicesitem-content">
                            <p>At Mudrainfotech we acknowledge that every single website, be it static or dynamic, responsive or an e-commerce, how crucial it is to design and develop it in the most interactive and efficient corporate website for your business.Our team of creative professionals work towards creating perfection in order to achieve the best for our clients.</p>

                        </div>


                    </div>

                    <div className="testingservices-item">

                        <div className="testingservicesitem-header">
                            <h3>Automation Testing </h3>

                        </div>

                        <div className="testingservicesitem-content">
                            <p>Automated software testing is critical to success in today’s competitive business climate. To get it right, you need to employ top testers who implement innovative automation frameworks using the most current tools. As test automation specialists, mudrainfotech offers a comprehensive range of test automation services to meet your goal of increasing velocity throughout the software development lifecycle </p>

                        </div>


                    </div>

                        <div className="testingservices-item">

                            <div className="testingservicesitem-header">
                                <h3>Mobile Testing</h3>

                            </div>

                            <div className="testingservicesitem-content">
                                <p>Mobile users have high expectations, which must be reflected in your mobile applications and their usability, performance and features, always keeping your customers in mind for a great user experience. Mobile app testing is not the same as software testing, and mobile apps are much more than small programs with less functionality and fewer challenges</p>
                            </div>


                        </div>

   


                </div>



            </div>

















    </div>





















    



)

export default QatestingUI;