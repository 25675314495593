import React from 'react';
import '../../../css/Blog.css';

import testing from './../../../Static/Images/AUTOMATION.jpg';
import { Link } from 'react-router-dom';


const Qatesting_blogUI = (props)=>(


    <div>

        

<div className="blog-layout">


    <div className="blog">

        <div className="blog-list">
            <div id="1">
                <div className="blog-item">

                    <div className="blog-img">

                        <img src = {testing} alt = ''/>

                    </div>

                    <div className="blog-heading">

                        <h1>Automation Testing in Agile Environment</h1>

                    </div>

                    <div className="blog-content">

                        <p>We need to ensure that a considerable amount of time is required for these tasks and in an agile environment where an average sprint takes an average 1-2 weeks to complete and thus it is obviously too difficult to contemplate affording so much time for automating scripts in such a way
                        </p>

                        <p>Agile methodology is a sort of technique which is very helpful for responding to frequentrequirement change introduced by the customer and which thus lends itself well to frequent changes during the overall development of the application. The primary importance of the process should be to ensure that in order to obtain the quality delivery of automation test scripts within a stipulated time as afforded by agile methodology; we need to choose our prospective test cases which would be automated in a way such that these automated test scripts lend themselves well for future re-use as well as ensuring that they can be prepared within the proper duration of the allotted time. </p>

                        

                        
                        <p>The reason to automate the test case is that the system will keep on adding new test cases as the new functionality is added with each subsequent sprint. This increases the total amount of functionality and code to be tested in every sprint, with the addition of new code old code still need to be regression tested. When a test automation strategy is developed, it is easy to inclined towards the thought to automate all the regression test, and that it should be done immediately. This is not economically justifiable; plus, not advisable to automate everything right away because manual testing will continue to play a major role in Agile environment. There are many factors which determine what feature should be automated first</p>
                    
                      

                        <p>     Some of the cases which typically justify their case are: </p>
                        <p>•	Business-critical functionality </p>
                        <p>•	Functions that are frequently used by many users .</p>
                        <p>•	Test cases which are related to the configuration</p>
                        <p>•	Test cases which will be run several times with different test data or where different permutation and combination can be applied</p>


                        
                    
                    
                    
                    
                    
                    </div>


                

                </div>    
            </div>
            






        
        







        

        </div>

    </div>


    <div className="blog-category">

        <div className="blogcategory-header">

            <h2>Categories</h2>

        </div>

        <div className="blogcategory-list">

            <ol>
            <li><a href="#">Blockchain</a></li>
            <li><a href="#">Digital Marketing</a></li>
            <li><a href="#">QA-Testing</a></li>
            <li><a href="#">Website Development</a></li>
            <li><a href="#">Mobile App Development</a></li>
            <li><a href="#">IOT</a></li>
            <li><a href="#">Robotic Process Automation</a></li>
            </ol>
        </div>


    </div>

</div>









    </div>



);

export default Qatesting_blogUI;