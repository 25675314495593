import React, {Component} from 'react';
import CareerUI from "../Presentation/CareerUI";


class Career extends Component{

    render(){
        return(
            <CareerUI/>
        );
    }
}


export default Career;
