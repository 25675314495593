import React from 'react';
import '../../../css/Blog.css';

import bc from './../../../Static/Images/bc.jpg';
import { Link } from 'react-router-dom';


const Blockchain_blogUI = (props)=>(


    <div>

        

<div className="blog-layout">


    <div className="blog">

        <div className="blog-list">
            <div id="1">
                <div className="blog-item">

                    <div className="blog-img">

                        <img src = {bc} alt = ''/>

                    </div>

                    <div className="blog-heading">

                        <h1>Blockchain Usage in Insurance and Healthcare Sector</h1>

                    </div>

                    <div className="blog-content">

                        <p>Blockchain is distributed and immutable (write once and read only) record of digital events that is
                            shared peer to peer between different parties (networked database systems). Blockchain transactions
                            are logged publicly and in chronological order.
                        </p>

                        <p>The database shows an ever-expanding list of ordered "blocks," each time-stamped and connected to the block that came before it — thereby constituting a blockchain.The blockchain record can be distributed and shared across networks, with credentialed users able to add to – but not delete or alter – the transaction log. Transactions are encrypted and must be verified by the network.In addition to being decentralized and collaborative, the information recorded to a blockchain is append-only, using cryptographic techniques that guarantee that once a transaction has been added to the ledger it cannot be modified.</p>

                        <h1>Insurance</h1>

                        
                        <p>Marine Insurance- EY is planning to launch the first blockchain platform for marine insurance, alongside Microsoft, A.P Moller-Maersk and others. The distributed ledger will be used to capture information about shipments, risk and liability, and to help firms comply with insurance regulations. It will also ensure transparency across an interconnected network of clients, brokers, insurers and other third parties. Marine industry is facing high administrative burdens of managing and writing claims with a lot of paperwork. All contracts are signed multiple times. They go from ship to ship, port to port, through quite a journey. Distributed ledgers are groupings of data shared across multiple locations without the need for central administrators and other middle men. The blockchain platform would enable the shipping giant to maintain a smoother relationship with the insurance market.  </p>
                    
                        <h1>Healthcare</h1>

                        <p>Clinical Health Data Exchange and Interoperability </p>
                        <p>•	Blockchain-enabled health IT systems that can provide technological solutions to many challenges, including health data interoperability, integrity and security, portable user-owned data and other areas. Most fundamentally, blockchain could enable data exchange systems that are cryptographically secured and irrevocable. </p>
                        <p>•	This would enable seamless access to historic and real-time patient data, while eliminating the burden and cost of data reconciliation.</p>
                        <p>•	Business Case Example - Collaboration between Guardtime, the data-centric security company, and the Estonian eHealth Foundation to secure the health records of one million Estonian citizens using its proprietary Keyless Signature Infrastructure (KSI) is a classic example of blockchain technology. When it comes to a master patient index, for instance, hospitals currently have as many as 20 different ways to enter a simple date-of-birth and no real way to standardize that once it’s been done. Blockchain could ease that by tying patients to their data, rather than identity.</p>


                        
                    
                    
                    
                    
                    
                    </div>


                

                </div>    
            </div>
            






        
        







        

        </div>

    </div>


    <div className="blog-category">

        <div className="blogcategory-header">

            <h2>Categories</h2>

        </div>

        <div className="blogcategory-list">

            <ol>
            <li><a href="#">Blockchain</a></li>
            <li><a href="#">Digital Marketing</a></li>
            <li><a href="#">QA-Testing</a></li>
            <li><a href="#">Website Development</a></li>
            <li><a href="#">Mobile App Development</a></li>
            <li><a href="#">IOT</a></li>
            <li><a href="#">Robotic Process Automation</a></li>
            </ol>
        </div>


    </div>

</div>









    </div>



);

export default Blockchain_blogUI;