import React from 'react';
import '../../css/Iot.css';








const IotUI = (props)=>(

    <div>

        <div className="iot-banner">

            {/* <div className="iot-header">

                <h1>Internet  <br></br>Of Things</h1>
            </div> */}



        </div>


        <div className="iot-define">

            <div className="iotdefine-list">


                <div className= "iotdefine-item">
                    <div className="iotdefine-itembox">

                        <div className="iotdefine-content">
                            <p>The internet of things involves embedded computing devices interconnected with the existing internet infrastructure.</p>

                            <p>Mudrainfotech provides full-range IoT services comprising consulting, development and implementation, analytics, support and evolution.Whether you plan to launch an IoT initiative, upscale an existing IoT application or integrate your IoT solution with a legacy system – we will work out a reliable and cost-effective way to address your business challenge.
                            </p>
                        </div>

                    </div>

                </div>

                <div className="iotpoints">

                    <div className="iotpoints-list">
                        <ul>
                            <li>Industrial – Manufacturing Industries – OEM’s</li>
                            <li>Healthcare – Medical Tech IoT Solution providers</li>
                            <li>Healthcare Information Technology companies</li>
                            <li>Smart Cities</li>
                            
                        </ul>
                    </div>
                </div>
    

 
            </div>
    



        </div>

        <div className="iot-services">

            <div className="iotservices-header">

                    <h1>Provide consultancy on the following areas :- </h1>

            </div>

            <div className="iotservices-list">

                <div className="iotservices-item">

                    <div className="iotservicesitem-header">
                        <h3>Smart, connected products </h3>

                    </div>

                    <div className="iotservicesitem-content">
                        <p>Our team helps product manufacturers to get a better understanding of the customers’ needs, optimize product functioning and use, and create new revenue models. We help define smart, connected products’ capabilities and set them in tune with your and your customers’ IT strategy.</p>

                    </div>


                </div>

                <div className="iotservices-item">

                    <div className="iotservicesitem-header">
                        <h3>Industrial IOT </h3>

                    </div>

                    <div className="iotservicesitem-content">
                        <p>We help manufacturers to catch the wave of digital transformation and optimize equipment utilization, reveal bottlenecks in the manufacturing process, reduce downtime, timely detect potential equipment failures, improve production quality, and increase employees’ safety. To achieve that, we closely cooperate with project stakeholders, analyze their business objectives, determine IoT system’s capabilities, create a detailed solution roadmap and bring it in line with an enterprise’s IT and business strategy.</p>

                    </div>


                </div>

                <div className="iotservices-item">

                    <div className="iotservicesitem-header">
                        <h3>Connected health </h3>

                    </div>

                    <div className="iotservicesitem-content">
                        <p>Our Internet of Things consultants help define the capabilities of your future IoT solution, advise on integrating it into your healthcare practice and setting it in sync with your business and IT strategies. We help you design robust and secure IoT solutions to connect patients to healthcare providers, digitize the approach to rendering healthcare services and optimize hospital management.</p>

                    </div>


                </div>

                <div className="iotservices-item">

                    <div className="iotservicesitem-header">
                        <h3>Smart cities, smart utilities</h3>

                    </div>

                    <div className="iotservicesitem-content">
                        <p>Our IoT consultants help municipalities, policymakers and other stakeholders by developing smart city use cases, creating a smart city vision and a roadmap, selecting technology vendors, launching PoC, creating servicing strategy, etc. With our vast knowledge and hands-on experience, we will help you plan, design and implement smart city solutions capable of enhancing urban infrastructure, optimizing energy management, reducing environmental pollution, and establishing more efficient communication between residents and urban facilities.</p>

                    </div>


                </div>



                

    



            </div>
        </div>





























    </div>


)

export default IotUI;