import React from 'react';
import '../../css/Webapp.css';
import gif from './../../Static/Images/coding.gif';



const WebappUI = (props)=>(


    <div>

        <div className="webapp-banner">

            <div className="Webapp-header">

                <h1>Website <br></br>Development</h1>
            </div>

        </div>

        <div className="webapp-define">

            <div className="webappdefine-list">
                <div className="webappdefine-item">

                    <div className="webappdefine-content">
                        <p>Web development is fundamental to any software product—and it is covered by Mudrainfotech in a full cycle of services: from business analysis and design to deployment and ongoing delivery. All technology needs of our clients are comprehensively covered by our dedicated teams.</p>
                    </div>

                </div>

                <div className="webappdefine-gif">

                   <img src = {gif} alt = ''/>

                    

                </div> 

            </div>


        </div>

        <div className="webapp-services">

            <div className="webappservices-header">

                <h1>Services</h1>

            </div>

            <div className="webappservices-list">

                <div className="webappservices-item">

                    <div className="webappservicesitem-header">
                        <h3>Website designing </h3>

                    </div>

                    <div className="webappservicesitem-content">
                        <p>At Mudrainfotech we acknowledge that every single website, be it static or dynamic, responsive or an e-commerce, how crucial it is to design and develop it in the most interactive and efficient corporate website for your business.Our team of creative professionals work towards creating perfection in order to achieve the best for our clients.</p>

                    </div>


                </div>

                <div className="webappservices-item">

                    <div className="webappservicesitem-header">
                        <h3>E Commerce Website  </h3>

                    </div>

                    <div className="webappservicesitem-content">
                        <p>A robust eCommerce website serves as the foundation of your online retail business, and Mudrainfotech understands the importance of utilizing the latest technology to maximize profitability. Our eCommerce solutions offer the best in standard features and functionality while remaining agile and completely customizable</p>

                    </div>


                </div>

                <div className="webappservices-item">

                    <div className="webappservicesitem-header">
                        <h3>Portal Development  </h3>

                    </div>

                    <div className="webappservicesitem-content">
                        <p>Mudrainfotech is well known for its Web Portal design and development. We develop professional and at the same time elegant B2B and B2C web portals. In B2B web portal we provide appropriate platform to numerous business organization or communities to boost and promote each other business ideas. In B2C web portals are developed and made attractive keeping in mind the mass appeal.</p>

                    </div>


                </div>

                <div className="webappservices-item">

                    <div className="webappservicesitem-header">
                        <h3>Project Management </h3>

                    </div>

                    <div className="webappservicesitem-content">
                        <p>Mudrainfotech helps in planning, organizing and delineating responsibility for the completion of an organizations' specific information technology (IT) goals. It oversee projects for software development, hardware installations, network upgrades, cloud computing and virtualization rollouts, business analytics and data management projects and implementing IT services. </p>

                    </div>


                </div>



            </div>



        </div>




    </div>



)

export default WebappUI;