import React, {Component} from 'react';
import Blockchain_blogUI from "../../Presentation/Blogs/Blockchain_blogUI";


class Blockchain_blog extends Component{

    render(){
        return(
            <Blockchain_blogUI/>
        );
    }
}


export default Blockchain_blog;