import React, {Component} from 'react';
import AboutusUI from "../Presentation/AboutusUI";


class Aboutus extends Component{

    render(){
        return(
            <AboutusUI/>
        );
    }
}


export default Aboutus;